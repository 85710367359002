import { groupServersByLevel } from './libLevelPanel';
import { useLevelList } from './useLevelList';

// Types
import type { IServerInfo } from '@/api/subscription';

// Components
import { Row } from 'react-bootstrap';
import LevelGrid from '../LevelGrid/LevelGrid';

// SCSS
import './LevelPanel.scss';

interface ILevelPanelProps {
    serverList: IServerInfo[];
}

const LevelPanel = ({ serverList }: ILevelPanelProps) => {
    const LevelMap = groupServersByLevel(serverList);
    const levelAmount = Object.keys(LevelMap).length;

    const { listsState, openAll, closeAll, setListState } =
        useLevelList(levelAmount);

    return (
        <div className="levelPanel">
            <div>
                <button className="levelPanel-button" onClick={openAll}>
                    Display all
                </button>
                <button className="levelPanel-button" onClick={closeAll}>
                    Collpase all
                </button>
            </div>
            <Row className="levelPanel-grid">
                <LevelGrid
                    levelMap={LevelMap}
                    levelListsState={listsState}
                    setListOpen={setListState}
                />
            </Row>
        </div>
    );
};

export default LevelPanel;
