// Types
import type { IHostInfo } from '@/api/tango/database';
import type { stateType } from '@/api/tango/types';

/**
 * Check if searched host is in this branch
 *
 * @param searched current searched server
 * @param hostList list of servers in branch
 *
 * @returns true if searched server is in this branch,
 *          otherwise - false
 */
export function checkIfserchedHostInBranch(
    searched: string,
    hostList: IHostInfo[],
) {
    for (const host of hostList) if (host.name === searched) return true;
    return false;
}

/**
 * Get state of branch based on servers in
 *
 * This was taken from Astor.
 *
 * @param serverList list of servers in brnach
 *
 * @returns state of branch
 */
export function getBranchState(serverList: IHostInfo[]): stateType {
    let state: stateType = 'ON';

    let isFault = false;
    let isAlarm = false;
    let isMoving = false;
    let okCount = 0;
    let offCount = 0;

    for (const server of serverList) {
        if (server.state === 'UNKNOWN') return 'UNKNOWN';
        else if (server.state === 'FAULT') isFault = true;
        else if (server.state === 'ALARM') isAlarm = true;
        else if (server.state === 'MOVING') isMoving = true;
        else if (server.state === 'OFF') offCount++;
        else if (server.state === 'ON') okCount++;
    }

    const hostAmount = serverList.length;

    if (isFault) state = 'FAULT';
    else if (isMoving) state = 'MOVING';
    else if (isAlarm) state = 'ALARM';
    else if (offCount === hostAmount) state = 'OFF';
    else if (okCount === hostAmount) state = 'ON';
    else state = 'ALARM';
    return state;
}
