import { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'universal-cookie';

// API
import { user } from '@/api/auth/user';

// Types
import type { authUserResponseType } from '@/api/auth/user';

export interface ISessionType {
    isLoggedIn: boolean;
    data: authUserResponseType;
}

const SessionContext = createContext<ISessionType>({
    isLoggedIn: false,
    data: null,
});

// temporary solution
type changeSessionContextType = React.Dispatch<ISessionType>;
export const ChangeSessionContext = createContext<changeSessionContextType>(
    () => null,
);

interface ISessionProviderProps {
    children: React.ReactElement;
}

// Is Context/ the right place for "session manager"?
export const SessionProvider = ({ children }: ISessionProviderProps) => {
    const [sessionState, setSessionState] = useState<ISessionType>({
        isLoggedIn: false,
        data: null,
    });

    // If we already have JWT token and it's not expired
    useEffect(() => {
        const cookie = new Cookies();
        const jwt = cookie.get('taranta_jwt');

        if (jwt) {
            user().then((response) => {
                if (response)
                    setSessionState({ isLoggedIn: true, data: response });
            });
        }
    }, []);

    return (
        <SessionContext.Provider value={sessionState}>
            <ChangeSessionContext.Provider value={setSessionState}>
                {children}
            </ChangeSessionContext.Provider>
        </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);
