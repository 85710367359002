import { createContext, useRef } from 'react';

export const BranchListScrollContext = createContext<IScrollFunctions>(
    undefined as unknown as IScrollFunctions,
);

export interface IScrollFunctions {
    addHostNode: (node: HTMLElement, hostName: string) => void;
    scrollToNode: (hostName: string) => void;
}

/**
 * Provide scrolling functions
 * @returns functions used to set element to scroll to and make scroll
 */
export const useScroll = (): IScrollFunctions => {
    const scrollReference = useRef(new Map());

    // Add host to map of hosts
    const addHostNode = (node: HTMLElement, hostName: string) => {
        if (node) scrollReference.current.set(hostName, node);
        else scrollReference.current.delete(hostName);
    };

    // Make a scroll to host
    const scrollToNode = (hostName: string) => {
        const node = scrollReference.current.get(hostName);
        if (node) node.scrollIntoView({ behavior: 'smooth' });
    };

    return { addHostNode, scrollToNode };
};
