import ReactDOM from 'react-dom/client';

// Components
import App from './app/App';

// SCSS
import './index.scss';

// React
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Can't find root element");

const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
