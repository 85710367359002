import { getCommandRespond, gqlRequestCommand } from '@/api/gql/request';

// Wraper function for all commands below
async function executeHostCommand(
    host: string,
    command: string,
    data: string | number,
) {
    const device = `tango/admin/${host}`;

    return await gqlRequestCommand(device, command, data).then((response) => {
        const commandResponse = getCommandRespond(response);
        if (commandResponse.ok) return `${command}: Success`;
        throw new Error(`${command}: ${commandResponse.message}`);
    });
}

/**
 * Start all servers in level
 *
 * @param host
 * @param level
 * @returns message on success
 */
export async function startLevel(host: string, level: string) {
    return await executeHostCommand(host, 'DevStartAll', Number(level));
}

/**
 * Stop all servers in level
 *
 * @param host
 * @param level
 * @returns message on success
 */

export async function stopLevel(host: string, level: string) {
    return await executeHostCommand(host, 'DevStopAll', Number(level));
}

/**
 * Start particular server
 *
 * @param host
 * @param serverToStart
 * @returns message on success
 */

export async function startServer(host: string, serverToStart: string) {
    return await executeHostCommand(host, 'DevStart', serverToStart);
}

/**
 * Stop particular server
 *
 * @param host
 * @param serverToStop
 * @returns message on success
 */

export async function stopServer(host: string, serverToStop: string) {
    return await executeHostCommand(host, 'DevStop', serverToStop);
}

/**
 * Kill process of particular server
 *
 * @param host
 * @param serverToKill
 * @returns message on success
 */
export async function hardKillServer(host: string, serverToKill: string) {
    return await executeHostCommand(host, 'DevStop', serverToKill);
}
