import { appStateDispatchContext } from '@/state/AppState';
import { useContext } from 'react';
import type { IServerList } from './ServerListContext';

/**
 * TODO: Load from local storage
 */
export const useServerListContext = () => {
    const dispatch = useContext(appStateDispatchContext);
    const dispatchServerList = (serverList: IServerList) =>
        dispatch({ type: 'CHANGE_SERVER_LIST', newServers: serverList });

    return { dispatchServerList };
};
