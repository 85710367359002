import { useConfigMetadata } from '@/context/ConfigProvider/ConfigProvider';

// Components
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import Loading from '@/components/Loading/Loading';
import CloudBody from '../CloudBody/CloudBody';

/**
 * Check if config have been prioperyl fetched and display rest of app
 */
const CloudInit = () => {
    const { error, loading } = useConfigMetadata();

    if (error)
        return (
            <div className="cloudBody-error-message">
                <ErrorMessage error={error.message} />
            </div>
        );
    else if (loading) return <Loading />;
    else return <CloudBody />;
};

export default CloudInit;
