import { sleep } from 'radash';

import { notfiyInfo } from '@/utils/notification';

import { startServer, stopServer } from '@/api/tango/host';

import { checkIfStoped } from './libRestartServer';

// Restart particular server
export async function restartServer(host: string, serverToRestart: string) {
    // This shouldn't be here
    notfiyInfo(`Restarting: ${serverToRestart}`);

    await stopServer(host, serverToRestart);

    // Check 10 times if server stoped
    const MAX_CHECKS = 10;
    for (let i = 0; i < MAX_CHECKS; i++) {
        const stoped = await sleep(3000).then(() =>
            checkIfStoped(host, serverToRestart),
        );

        if (stoped) return await startServer(host, serverToRestart);
    }
    throw new Error("Can't restart server");
}
