// API
import { LevelColumn } from '../LevelColumn/LevelColumn';
import type { ILevelList } from '../LevelPanel/libLevelPanel';
import { countColumnLevelIndex } from './libLevelGrid';

import useWindow from '@/hooks/useWindow/useWindow';

interface ILevelGridProps {
    levelMap: ILevelList;
    levelListsState: boolean[];
    setListOpen: (which: number, state: boolean) => void;
}

/**
 * Group and display servers by level
 */
const LevelGrid = ({
    levelMap,
    levelListsState,
    setListOpen,
}: ILevelGridProps) => {
    const { lg, xxl } = useWindow();

    // Constants
    const columnAmount = xxl ? 3 : lg ? 2 : 1;
    const colLvlIndex = countColumnLevelIndex(levelMap, columnAmount);

    return (
        <>
            {colLvlIndex.map((levelIndexes, colIndex) => (
                <LevelColumn
                    key={colIndex}
                    levelIndexes={levelIndexes}
                    levelMap={levelMap}
                    levelListsState={levelListsState}
                    setListOpen={setListOpen}
                />
            ))}
        </>
    );
};

export default LevelGrid;
