// Types
import type { stateType } from '@/api/tango/types';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';
import BranchMenu from '../BranchMenu/BranchMenu';

// MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// SCSS
import './BranchHeader.scss';

interface IBranchHeaderProps {
    branchName: string;
    branchState: stateType;
    isListOpen: boolean;
    setServerListOpen: (open: boolean) => void;
}

/**
 * Header of the branch
 *
 * The core function of this component is to give information
 * about branch and give ability to open list of hosts.
 *
 * @param branchName
 * @param branchState
 * @param isListOpen
 * @param setServerListOpen function that change list open state
 */
const BranchHeader = ({
    branchName,
    branchState,
    isListOpen,
    setServerListOpen,
}: IBranchHeaderProps) => {
    // Couldn't we put it in Branch component and cut some function arguments?
    const toogleListStatus = () => setServerListOpen(!isListOpen);

    return (
        <div className="branchHeader">
            <ListItemButton
                className="deviceList-item"
                onClick={toogleListStatus}
                disableGutters
            >
                <ListItemText className="deviceList-item-header">
                    <StatusDot state={branchState} size="medium" />
                    <span className="deviceList-item-header-name">
                        {branchName}
                    </span>
                </ListItemText>
            </ListItemButton>
            <BranchMenu />
        </div>
    );
};

export default BranchHeader;
