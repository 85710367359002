// Components
import type { IDialogState } from '@/components/Dialog/Dialog';

interface ILevelActionDialogProps {
    dialog: IDialogState;
    setDialog: (newDialog: IDialogState) => void;
}

/**
 * Display dialog for LevelActionBoard if one was set
 */
const LevelActionDialog = ({ dialog, setDialog }: ILevelActionDialogProps) => {
    const DialogComponent = dialog.component;
    const handleCloseDialog = () =>
        setDialog({ isOpen: false, component: null });

    return DialogComponent ? (
        <DialogComponent isOpen={dialog.isOpen} close={handleCloseDialog} />
    ) : null;
};

export default LevelActionDialog;
