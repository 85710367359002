import { useContext } from 'react';

import { ChangeHostContext, ChangeTabContext } from '@/context/Context';

const useNavbarLogo = () => {
    const changeGlobalHost = useContext(ChangeHostContext);
    const changeTab = useContext(ChangeTabContext);

    /* Isn't it misleading to set host to Database? */
    const handleLogoClick = () => {
        changeGlobalHost('Database');
        changeTab('Info');
    };

    return { handleLogoClick };
};

export default useNavbarLogo;
