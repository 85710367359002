// Components
import type { IDatabaseInfo, IHostInfo } from '@/api/tango/database';
import CloudDeviceList from '../CloudDeviceList/CloudDeviceList';
import ContentPicker from '../ContentPicker/ContentPicker';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import './CloudContent.scss';

interface ICloudContentProps {
    hostList: IHostInfo[];
    databaseList: IDatabaseInfo[];
}

/**
 * This component divide cloud ont two sides device list
 * and main content of app. Also create resposive layout
 */
const CloudContent = ({ hostList, databaseList }: ICloudContentProps) => {
    return (
        <Row style={{ height: '92%' }}>
            <Col xs={12} lg={3} className="cloudContainer-deviceList">
                <CloudDeviceList
                    databaseList={databaseList}
                    hostList={hostList}
                />
            </Col>
            <Col xs={12} lg={9} className="cloudContainer-contentPicker">
                <ContentPicker />
            </Col>
        </Row>
    );
};

export default CloudContent;
