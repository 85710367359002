// Components
import CloudInit from './CloudInit/CloudInit';

// Bootstrap
import { Container } from 'react-bootstrap';

//SCSS
import './Cloud.scss';

const Cloud = () => {
    return (
        <Container className="cloud">
            <CloudInit />
        </Container>
    );
};

export default Cloud;
