import { useContext } from 'react';

// Context
import { ChangeSessionContext } from '@/context/Session';

// API
import { logout } from '@/api/auth/logout';

// SCSS
import './LogOut.scss';

interface ILogOutProps {
    user: string | null;
}

/**
 * Display current logged in user and logout button
 *
 * NOTE: Isn't it misleading to print user in logout???
 */
const LogOut = ({ user }: ILogOutProps) => {
    const changeCurrentSession = useContext(ChangeSessionContext);

    const handleClick = () => {
        logout().then(() =>
            changeCurrentSession({ isLoggedIn: false, data: null }),
        );
    };

    return (
        <div className="log-info">
            {user}
            <button className="logOut-button" onClick={handleClick}>
                Log out
            </button>
        </div>
    );
};

export default LogOut;
