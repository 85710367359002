import { useDeviceData } from '@/context/DeviceData';

// MUI
import ListItem from '@mui/material/ListItem';

/**
 * Display error when some occur
 */
const DeviceListError = () => {
    const { error } = useDeviceData();

    return error ? (
        <ListItem component="div">
            <p>{error.message}</p>
        </ListItem>
    ) : null;
};

export default DeviceListError;
