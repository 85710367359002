import type { IPolkaConfig } from '@/data/config';
import { useFetch } from '@/hooks/useFetch';
import { createContext, useCallback, useContext, useEffect } from 'react';

export interface IConfigMetadata {
    loading: boolean;
    error?: Error;
}

const configMetadataContext = createContext<IConfigMetadata>({
    loading: true,
    error: undefined,
});

export const useConfigMetadata = () => useContext(configMetadataContext);

interface IConfigFetcher {
    children: JSX.Element;
}

const ConfigProvider = ({ children }: IConfigFetcher) => {
    const fetchConfig = useCallback(async (): Promise<IPolkaConfig> => {
        const response = await fetch('/config.json');
        const data = await response.json();
        return data;
    }, []);
    const { value: config, loading, error } = useFetch(fetchConfig);

    useEffect(() => {
        window.POLKA_CONFIG = config;
    }, [config]);

    return (
        <configMetadataContext.Provider value={{ loading, error }}>
            {children}
        </configMetadataContext.Provider>
    );
};

export default ConfigProvider;
