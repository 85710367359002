import {
    BranchInfoDialog,
    ChangeNameDialog,
    StartAllServerDialog,
    StopAllServerDialog,
} from '../BranchMenuDialog/BranchMenuDialog';

/**
 * Kebab menu for Branch
 */
const BranchMenu = () => {
    const menuOptions = {
        'Branch info': BranchInfoDialog,
        'Start All Server': StartAllServerDialog,
        'Stop All Server': StopAllServerDialog,
        'Change Name': ChangeNameDialog,
    };

    return null; //<KebabMenu options={menuOptions} />;
};

export default BranchMenu;
