import React from 'react';

// API
import Notification from '@/components/Notification/Notification';

// Context
import {
    ChangeHostContext,
    ChangeSearchContext,
    ChangeTabContext,
    HostContext,
    SearchContext,
    TabContext,
} from '@/context/Context';
import { SessionProvider } from '@/context/Session';
import StateProvider from '@/context/StateProvider';

// Types
import type {
    hostContextType,
    searchContextType,
    tabContextType,
} from '@/context/Context';

// React-tostify
import 'react-toastify/dist/ReactToastify.css';

// Bootstrap - but only styles that we need
import '@/assets/styles/bootstrap.scss';

// Components
import Cloud from '@/app/Cloud/Cloud';

// SCSS
import ConfigProvider from '@/context/ConfigProvider/ConfigProvider';
import './App.scss';

interface IAppStateProps {
    children: React.ReactElement;
}

/**
 * Component for all global contexts
 *
 * NOTE: Maybe we should use redux???
 */
const AppState = ({ children }: IAppStateProps) => {
    // TODO: Create component for each
    const [search, changeSearch] = React.useState<searchContextType>({
        server: '',
        host: '',
    });
    const [host, changeHost] = React.useState<hostContextType>('Database');
    const [tab, changeTab] = React.useState<tabContextType>('Info');

    // hmmm??
    return (
        <StateProvider>
            <SessionProvider>
                {/* Search */}
                <SearchContext.Provider value={search}>
                    <ChangeSearchContext.Provider value={changeSearch}>
                        {/* Host */}
                        <HostContext.Provider value={host}>
                            <ChangeHostContext.Provider value={changeHost}>
                                {/* Tab */}
                                <TabContext.Provider value={tab}>
                                    <ChangeTabContext.Provider
                                        value={changeTab}
                                    >
                                        <ConfigProvider>
                                            {children}
                                        </ConfigProvider>
                                    </ChangeTabContext.Provider>
                                </TabContext.Provider>
                            </ChangeHostContext.Provider>
                        </HostContext.Provider>
                    </ChangeSearchContext.Provider>
                </SearchContext.Provider>
            </SessionProvider>
        </StateProvider>
    );
};

// :)
const App = () => {
    return (
        <React.StrictMode>
            <AppState>
                <div className="app">
                    <Notification />
                    <Cloud />
                </div>
            </AppState>
        </React.StrictMode>
    );
};

export default App;
