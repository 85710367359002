// Components
import CreateServer from './CreateServer/CreateServer';
import StartNew from './StartNew/StartNew';

// Do we need this component???
const HostAddServer = () => {
    return (
        <>
            <StartNew />
            <CreateServer />
        </>
    );
};

export default HostAddServer;
