import { useContext } from 'react';

import { FoundType, search } from '@/utils/search/search';

// Context
import {
    ChangeHostContext,
    ChangeSearchContext,
    ChangeTabContext,
} from '@/context/Context';

import { useServerList } from '@/context/ServerListContext';
import { changeContext, validateInput } from '../libSearchBar';

const useSearchBar = () => {
    const changeHost = useContext(ChangeHostContext);
    const changeTab = useContext(ChangeTabContext);
    const changeSearch = useContext(ChangeSearchContext);

    const serverHostList = useServerList();

    function searchFunction(input: string) {
        validateInput(input);
        const searchedData = search(input, serverHostList);
        changeContext(searchedData, changeHost, changeTab, changeSearch);

        const type =
            searchedData.type === FoundType.SERVER
                ? searchedData.server
                : searchedData.host;

        return `${type} found`;
    }

    return searchFunction;
};

export default useSearchBar;
