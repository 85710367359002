// Types
import type { IServerInfo } from '@/api/subscription';
import type { IKebabMenuItem } from '@/components/KebabMenu/KebabMenu';

const configureWithTaranta = (server: IServerInfo) => {
    const Config = window.POLKA_CONFIG;

    const serverName = server.name.toLocaleLowerCase();

    const taranta_url = Config.taranta_url;
    const redirect_url = `${taranta_url}/devices/dserver/${serverName}/server`;

    const newWindow = window.open(
        redirect_url,
        '_blank',
        'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
};

const createConfigureWithTaranta = (server: IServerInfo): IKebabMenuItem => {
    return {
        component: null,
        onClick: () => configureWithTaranta(server),
    };
};

export default createConfigureWithTaranta;
