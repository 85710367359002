import { gqlRequest } from '@/api/gql/request';

import { DB_GET_SERVERS_QUERY } from '@/api/gql/query';

import type { IDatabaseGetServersDTO } from '@/api/gql/types';
import type { stateType } from '@/api/tango/types';

import { getAllInstancesCreateReadable } from './libGetAllInstances';

// TODO: Change to IInstanceInfo
export interface IServerInstance {
    server: string;
    name: string;
    state: stateType;
    host: string | null;
}

// Get all server instances
export async function getAllInstances(): Promise<IServerInstance[]> {
    const response = await gqlRequest<IDatabaseGetServersDTO>(
        DB_GET_SERVERS_QUERY,
    );

    return getAllInstancesCreateReadable(response);
}
