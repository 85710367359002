import type { stateType } from '@/api/tango/types';

export const polkaColorGreen = '#4CAF50';
export const polkaColorRed = '#F44336';
export const polkaColorOrange = '#FF9800';
export const polkaColorBlue = '#2196f3';
export const polkaColorGrey = '#D8D8DF';

/**
 * Translate state to color
 *
 * NOTE: Default export until we put next functions here
 * @param state
 */
export default function getColorByState(state: stateType) {
    if (state === 'ON') return polkaColorGreen;
    else if (state === 'OFF' || state === 'UNKNOWN' || state === 'FAULT')
        return polkaColorRed;
    else if (state === 'ALARM') return polkaColorOrange;
    else if (state === 'MOVING') return polkaColorBlue;

    /**
     .STANDBY {}

    .CLOSE,
    .OPEN,
    .INSERT,
    .EXTRACT,
    .INIT,
    .RUNNING,
    .DISABLE {
        background-color: none;
    }
    */
}
