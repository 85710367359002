// Components
import BranchList from './BranchList/BranchList';
import DatabaseList from './DatabaseList/DatabaseList';
import DeviceListError from './DeviceListError/DeviceListError';
import SearchBar from './SearchBar/SearchBar';

// MUI
import List from '@mui/material/List';

// SCSS
import './DeviceList.scss';

import type { IDatabaseInfo, IHostInfo } from '@/api/tango/database';

interface IDeviceListProps {
    databases: IDatabaseInfo[];
    hosts: IHostInfo[];
}

const DeviceList = ({ databases, hosts }: IDeviceListProps) => {
    return (
        <List component="div" className="deviceList">
            <DeviceListError />
            <div className="deviceList-searchbar">
                <SearchBar />
            </div>
            <div className="deviceList-databaseList">
                <DatabaseList db={databases} />
            </div>
            <div className="deviceList-branchList">
                <BranchList hostList={hosts} />
            </div>
        </List>
    );
};

export default DeviceList;
