// Utils
import getColorByState, { polkaColorGrey } from '@/utils/colors';

// Types
import type { stateType } from '@/api/tango/types';

// SCSS
import './LevelPreview.scss';

interface ILevelPreviewProps {
    levelState: stateType;
    runningServers: number;
    turnedOffServers: number;
    otherServers: number;
}

/**
 * Display amout of running, turned off and servers in other state
 *
 * Running servers have green color
 * Turned off have red color
 * Servers in other states have grey color
 *
 * @param levelState
 * @param runningServers
 * @param turnedOffServers
 * @param otherServers
 */
const LevelPreview = ({
    levelState,
    runningServers,
    turnedOffServers,
    otherServers,
}: ILevelPreviewProps) => {
    const otherColorState =
        levelState === 'ON' ||
        levelState === 'OFF' ||
        levelState === 'UNKNOWN' ||
        levelState === 'FAULT'
            ? polkaColorGrey
            : getColorByState(levelState);
    return (
        <div className="levelPreview">
            <p style={{ color: getColorByState('ON') }}>{runningServers}</p>
            <p className="levelPreview-gap"> | </p>
            <p style={{ color: getColorByState('OFF') }}>{turnedOffServers}</p>
            <p className="levelPreview-gap"> | </p>
            <p style={{ color: otherColorState }}>{otherServers}</p>
        </div>
    );
};

export default LevelPreview;
