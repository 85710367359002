import { gql } from 'graphql-request';

/* ====== REQUEST ====== */

export const REQ_EXECUTE_COMMAND_QUERY = gql`
    mutation ExecuteCommand(
        $device: String!
        $command: String!
        $argin: ScalarTypes
    ) {
        executeCommand(device: $device, command: $command, argin: $argin) {
            ok
            message
            output
        }
    }
`;

/* ====== DATABASE ====== */

export const DB_GET_INFO_STRING_QUERY = gql`
    query {
        info
    }
`;

export const DB_GET_ALL_DB_QUERY = gql`
    query {
        database: devices(pattern: "sys/database/*") {
            name
            state
        }
    }
`;

export const DB_GET_HOSTS_QUERY = gql`
    query {
        hosts: devices(pattern: "tango/admin/*") {
            name
            state
            properties(pattern: "HostCollection") {
                value
            }
        }
    }
`;

export const DB_GET_SERVERS_QUERY = gql`
    query {
        allServers: servers {
            instances {
                name
                server
                classes(pattern: "dserver") {
                    devices {
                        state
                        server {
                            host
                        }
                    }
                }
            }
        }
    }
`;

/* ====== TANGO HOST ====== */

export const HOST_GET_SERVERS_QUERY = gql`
    query StarterGetServers($atr: [String]!) {
        servers: attributes(fullNames: $atr) {
            value
        }
    }
`;

export const HOST_GET_INFO_QUERY = gql`
    query Dev($name: String!) {
        device(name: $name) {
            name
            state
            connected
            server {
                id
                host
            }
            pid
            startedDate
            stoppedDate
            exported
        }
    }
`;

/* ====== CLIENT ====== */

export const CLIENT_PING_QUERY = gql`
    query Ping($dev: String!) {
        device(name: $dev) {
            attributes(pattern: "state") {
                value
            }
        }
    }
`;

/* SUBSCRIPTION */

export const HOST_SERVERS_SUBSCRIPTION_QUERY = gql`
    subscription ServerSubscription($hosts: [String]!) {
        servers: attributes(fullNames: $hosts) {
            value
        }
    }
`;
