import Loading from '@/components/Loading/Loading';
import useHostLogs from './useHostLogs';

// Components
import LogViewer from './LogViewer/LogViewer';

/**
 * Fetch and display all logs that belong to the current host
 */
const HostLogs = () => {
    const { value, loading, error, page, setPage } = useHostLogs();

    if (error) return <p>{error.message}</p>;
    else if (loading) return <Loading />;
    else return <LogViewer response={value} page={page} setPage={setPage} />;
};

export default HostLogs;
