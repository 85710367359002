import { getCommandRespond, gqlRequestCommand } from '@/api/gql/request';

import { DATABASEDS_DEV } from '@/data/constants';

import type { DbDevExportInfo } from '@/api/tango/database';

// Export device
export async function exportDevice(info: DbDevExportInfo) {
    const argin = [info.name, info.ior, info.host, info.pid, info.ver];

    const response = await gqlRequestCommand(
        DATABASEDS_DEV,
        'DbExportDevice',
        argin,
    );

    const exportResp = getCommandRespond(response);

    if (exportResp.ok) return 'Success';
    else throw new Error(`Error: ${exportResp.message.join()}`);
}
