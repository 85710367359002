import {
    DbDevExportInfo,
    exportDevice,
    unexportDevice,
} from '@/api/tango/database';

export async function registerServer(host: string, server: string) {
    const device = `dserver/${server}`;
    const info = new DbDevExportInfo(device, 'null', host, 'null', 'null');

    await exportDevice(info);
    await unexportDevice(device);

    return 'Success';
}
