import { isEmpty } from 'radash';
import { useEffect } from 'react';
import { useInstanceContext } from './useInstanceContext';

interface IInstanceProviderProps {
    children: JSX.Element;
}

/**
 * The purpoose of this component is to fetch or read from
 * local store all tango servers instances
 */
export const InstanceProvider = ({ children }: IInstanceProviderProps) => {
    const { fetchInstances, dispatchInstances } = useInstanceContext();
    useEffect(() => {
        const rawState = localStorage.getItem('inst');
        const stateData = rawState ? JSON.parse(rawState) : rawState;

        // If data isn't empty set state with this data
        if (!isEmpty(stateData))
            dispatchInstances({ value: stateData, loading: false });

        // And fetch fresh data anyway
        fetchInstances();
    }, []);

    return children;
};
