import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export interface IDialogComponentProps {
    isOpen: boolean;
    close: () => void;
}
export type dialogComponentType = ({
    isOpen,
    close,
}: IDialogComponentProps) => React.ReactElement<IDialogComponentProps>;

export type dialogType = dialogComponentType | null;

export interface IDialogState {
    isOpen: boolean;
    component: dialogType;
}

interface IAcceptDialogProps {
    message: string;
    fn: () => void;
    isOpen: boolean;
    close: () => void;
}

export const AcceptDialog = ({
    message,
    fn,
    isOpen,
    close,
}: IAcceptDialogProps) => {
    const dialogOnClick = () => {
        fn();
        close();
    };

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{message}</DialogTitle>
            <DialogActions>
                <Button onClick={dialogOnClick}>OK</Button>
                <Button onClick={close}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};
