import type { IDatabaseGetServersDTO } from '@/api/gql/types';
import type { IServerInstance } from './getAllInstances';

export function getAllInstancesCreateReadable(dto: IDatabaseGetServersDTO) {
    const noramlizedInstanceList: IServerInstance[] = [];

    // Iterate through allServers
    for (const server of dto.allServers) {
        // Iterate through server instances
        for (const instanceInfo of server.instances) {
            const instanceState = instanceInfo?.classes[0]?.devices[0]?.state;
            const instanceServer = instanceInfo?.classes[0]?.devices[0]?.server;

            const instanceHost =
                instanceServer === null ? null : instanceServer.host;
            const noramlizedInstanceInfo = {
                server: instanceInfo?.server,
                name: instanceInfo?.name,
                state: instanceState,
                host: instanceHost,
            };

            noramlizedInstanceList.push(noramlizedInstanceInfo);
        }
    }
    return noramlizedInstanceList;
}
