// Types
import { FoundType } from './search';

export function getType(str: string) {
    const pos = str.search('/');
    return pos === -1 ? FoundType.HOST : FoundType.SERVER;
}

export function getHostname(host: string | null) {
    if (!host) return null;

    const hostname = host.split('.')[0];
    return hostname;
}
