import { useContext, useEffect, useState } from 'react';

// API
import type { IServerInfo } from '@/api/subscription';
import { checkIfserched } from './libLevel';

// Context
import { SearchContext } from '@/context/Context';

// Bootstrap

const useLevelSearch = (
    level: number,
    serversInLevel: IServerInfo[],
    setListOpen: (which: number, state: boolean) => void,
) => {
    // NOTe: Maybe we can make search without state,
    // maybe useMemo or something like this?
    const [isSearched, setSerched] = useState(false);
    const searchedServer = useContext(SearchContext).server;

    // Check when searchedContext changed
    useEffect(() => {
        const setSearchHere = () => {
            setListOpen(Number(level), true);
            setSerched(true);
        };

        if (checkIfserched(serversInLevel, searchedServer)) setSearchHere();
        else setSerched(false);
    }, [searchedServer]);

    return isSearched;
};

export default useLevelSearch;
