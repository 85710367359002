import type { IStarterLogs } from '@/api/tango/host';

// MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'radash';
import StarterLogInstance from '../StarterLogInstance/StarterLogInstance';

import './StarterLogViewer.scss';

interface IStarterLogViewerProps {
    starterLogs: IStarterLogs[];
}

/**
 * Display table wit all starter logs
 *
 * @param starterLogs - table with all starter logs
 */
const StarterLogViewer = ({ starterLogs }: IStarterLogViewerProps) => {
    if (isEmpty(starterLogs)) return <p>It looks like there is no logs</p>;
    else
        return (
            <div className="starterLogViewer">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {starterLogs.map((log) => (
                                <StarterLogInstance log={log} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
};

export default StarterLogViewer;
