import type { stateType } from '@/api/tango/types';

import getColorByState from '@/utils/colors';

// SCSS
import './StatusDot.scss';

type statusDotSizeType = 'small' | 'medium' | 'big';

interface IStatusDotProps {
    state: stateType;
    size: statusDotSizeType;
}

/**
 * Component for displaying dot with given state
 * @param state
 * @param size size of the dot
 */
const StatusDot = ({ state, size }: IStatusDotProps) => {
    const type = state === 'STANDBY' ? 'triangle' : 'dot';

    return (
        <span
            style={{ backgroundColor: getColorByState(state) }}
            className={`status-${type} ${size} ${state}`}
        ></span>
    );
};

export default StatusDot;
