// Components
import DeviceList from '@/app/DeviceList/DeviceList';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import DeviceListDrawer from './DeviceListDrawer/DeviceListDrawer';

// Types
import type { IDatabaseInfo, IHostInfo } from '@/api/tango/database';

interface ICloudDeviceListProps {
    hostList: IHostInfo[];
    databaseList: IDatabaseInfo[];
}

/**
 * Display device list in cloud, but depend on window size.
 *
 * On mobile, display hamburger menu and drawer with device list.
 * On PC jsut display device list.
 */
const CloudDeviceList = ({ hostList, databaseList }: ICloudDeviceListProps) => {
    // meno or something???
    const deviceList = <DeviceList databases={databaseList} hosts={hostList} />;

    return (
        <>
            <Breakpoint xs lg={false}>
                <DeviceListDrawer deviceList={deviceList} />
            </Breakpoint>
            <Breakpoint xs={false} lg>
                {deviceList}
            </Breakpoint>
        </>
    );
};

export default CloudDeviceList;
