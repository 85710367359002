import type { ILoginInfo } from './login';

export type authUserResponseType = ILoginInfo | null;

/**
 * Check if token is stil valid
 *
 * @returns decoded by server JWT or null
 */
export async function user(): Promise<authUserResponseType> {
    return await fetch('/auth/user', { credentials: 'include' }).then(
        (response) => response.json(),
    );
}
