import { useState } from 'react';

// Utils
import { polkaColorGreen, polkaColorRed } from '@/utils/colors';

// Components
import StartAll from './Dialogs/StartAllDialog';
import StopAll from './Dialogs/StopAllDialog';
import LevelActionDialog from './LevelActionDialog/LevelActionDialog';

// MUI
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';

// Types
import type {
    IDialogComponentProps,
    IDialogState,
} from '@/components/Dialog/Dialog';

// SCSS
import './LevelActionBoard.scss';

interface ILevelActionBoardProps {
    level: number;
}

/**
 * Display buttons whicvh will start and stop all servers in level
 *
 * @param level
 */
const LevelActionBoard = ({ level }: ILevelActionBoardProps) => {
    const [dialog, setDialog] = useState<IDialogState>({
        isOpen: false,
        component: null,
    });

    // TangoGQL require level to be string
    const levelString = String(level);

    const startAllDialog = () =>
        setDialog({
            isOpen: true,
            component: ({ isOpen, close }: IDialogComponentProps) => (
                <StartAll level={levelString} isOpen={isOpen} close={close} />
            ),
        });

    const stopAllDialog = () =>
        setDialog({
            isOpen: true,
            component: ({ isOpen, close }: IDialogComponentProps) => (
                <StopAll level={levelString} isOpen={isOpen} close={close} />
            ),
        });

    return (
        <div className="levelActionBoard">
            <IconButton
                sx={{ color: polkaColorGreen }}
                onClick={startAllDialog}
            >
                <PlayArrowIcon />
            </IconButton>
            <IconButton sx={{ color: polkaColorRed }} onClick={stopAllDialog}>
                <StopIcon />
            </IconButton>
            <LevelActionDialog dialog={dialog} setDialog={setDialog} />
        </div>
    );
};

export default LevelActionBoard;
