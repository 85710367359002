import { HOST_SERVERS_SUBSCRIPTION_QUERY } from '@/api/gql/query';
import gql from 'graphql-tag';
import type { stateType } from '../tango/types';
import { Subscription } from './subscription';

export interface IServerInfo {
    name: string;
    state: stateType;
    controlled: string;
    level: string;
    instance: string;
}

export class ServerSubscription extends Subscription {
    subscriptionList: Array<ZenObservable.Subscription>;
    constructor() {
        super();

        this.subscriptionList = [];
    }

    _makeObservable(server: string) {
        const payload = {
            query: gql(HOST_SERVERS_SUBSCRIPTION_QUERY),
            variables: {
                hosts: [`tango/admin/${server}/Servers`],
            },
        };

        return this.client.subscribe(payload);
    }

    subscribe(server: string, dispatch: (data: unknown) => void) {
        const observable = this._makeObservable(server);

        const subscription = observable.subscribe(dispatch);
        this.subscriptionList.push(subscription);
    }

    unsubscribeAll() {
        for (const subscription of this.subscriptionList)
            subscription.unsubscribe();
    }
}
