// Types
import type { IDatabaseInfo, IHostInfo } from '@/api/tango/database';

import { useDbList } from './DbContext';
import { useHostsList } from './HostsContext';

export interface IUseDeviceDataType {
    hostList: IHostInfo[];
    databaseList: IDatabaseInfo[];
    loading: boolean;
    error?: Error;
}

export const useDeviceData = (): IUseDeviceDataType => {
    const databaseList = useDbList();
    const hostList = useHostsList();

    const loading = databaseList.loading || hostList.loading;
    const error = databaseList.error ?? hostList.error ?? undefined;

    return {
        hostList: hostList.value,
        databaseList: databaseList.value,
        loading: loading,
        error: error,
    };
};
