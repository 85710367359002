import { createContext, useState } from 'react';

// MUI
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

// TODO: There have to be a better way to do this than using contex
export const drawerContext = createContext<(open: boolean) => void>(
    // Is this a good way to make empty function?
    () => undefined,
);

interface IDeviceListDrawerProps {
    deviceList: React.ReactElement;
}

/**
 * Display hamburger menu and with drawer
 *
 * @param deviceList deviceList component to display in drawer
 */
const DeviceListDrawer = ({ deviceList }: IDeviceListDrawerProps) => {
    const [drawerOpen, setOpenDrawer] = useState(false);

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        const keyboard = event as React.KeyboardEvent;
        if (
            event.type === 'keydown' &&
            (keyboard.key === 'Tab' || keyboard.key === 'Shift')
        )
            return;
        setOpenDrawer(!drawerOpen);
    };

    return (
        <>
            <IconButton onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <drawerContext.Provider value={setOpenDrawer}>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                    <div style={{ height: '5%' }}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    {deviceList}
                </Drawer>
            </drawerContext.Provider>
        </>
    );
};

export default DeviceListDrawer;
