// API
import { useAllInstances } from '@/context/InstanceContext';

// Components
import { isEmpty } from 'radash';
import InstanceList from './InstanceList/InstanceList';

/**
 * Display all server instances and button which export and start
 * server.
 *
 * TODO: Make popup which ask for startup level
 */
const StartNew = () => {
    const { value, error } = useAllInstances();

    if (isEmpty(value)) {
        if (error) return <p>Error: {error.message}</p>;
        else return <p>Loading</p>;
    } else return <InstanceList serverInstances={value} />;
};

export default StartNew;
