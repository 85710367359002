import { isEmpty } from 'radash';

import type { IDatabaseGetHostsDTO } from '@/api/gql/types';
import type { IHostInfo } from './getAllHosts';

export function getAllHostsCreateReadable(
    dto: IDatabaseGetHostsDTO,
): IHostInfo[] {
    return dto.hosts.map((host) => {
        const hostName = host.name.replace('tango/admin/', '');
        const hostState = host.state;
        const hostBranch = isEmpty(host.properties)
            ? 'Miscellaneous'
            : host.properties[0].value[0];

        return {
            name: hostName,
            state: hostState,
            branch: hostBranch,
        };
    });
}
