import { useState } from 'react';

// Components
import LoginDialog from '../LoginDialog/LoginDialog';

// SCSS
import './LogIn.scss';

/**
 * Display login button and LoginDialog when user want to login
 */
const LogIn = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = () => setModalOpen(true);

    return (
        <div className="logIn-info">
            Not logged in
            <button className="logIn-button" onClick={handleOpen}>
                Log in
            </button>
            <LoginDialog open={modalOpen} setOpen={setModalOpen} />
        </div>
    );
};

export default LogIn;
