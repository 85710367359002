// API
import * as TangoHost from '@/api/tango/host';

// Components
import { notfiyError, notifySuccess } from '@/utils/notification';

export async function addServerToHost(hostName: string, server: string) {
    await TangoHost.startExistingServer(hostName, server)
        .then((resp) => {
            if (resp) notifySuccess(`Successfully added ${server}`);
        })
        .catch((err) => {
            notfiyError(err.message);
        });
}
