// API
import type { IServerInfo } from '@/api/subscription';

// Components
import ServerInstance from '../ServerInstance/ServerInstance';

// MUI
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

import './LevelServerList.scss';

interface ILevelServerListProps {
    serversInLevel: IServerInfo[];
    isListOpen: boolean;
}

/**
 * Display collapsed list of servers
 *
 * Button for toggling Collapse is in LevelHeader component,
 * here we just display the list
 */
const LevelServerList = ({
    serversInLevel,
    isListOpen,
}: ILevelServerListProps) => {
    return (
        <Collapse
            role="list"
            in={isListOpen}
            timeout="auto"
            className="levelServerList-collapse"
        >
            <List className="levelServerList" component="div">
                {serversInLevel.map((servInfo) => (
                    <ServerInstance
                        key={`${servInfo.name}/${servInfo.instance}`}
                        serverInfo={servInfo}
                    />
                ))}
            </List>
        </Collapse>
    );
};

export default LevelServerList;
