import { JWT_COOKIE_NAME } from '@/data/constants';
import Cookies from 'universal-cookie';

/**
 * Logout user and delete session cookie
 *
 * @returns empty promise
 */
export async function logout(): Promise<unknown> {
    return await fetch('auth/logout', {
        method: 'POST',
        credentials: 'include',
    }).then(() => {
        const cookie = new Cookies();
        cookie.remove(JWT_COOKIE_NAME, { path: '/' });
    });
}
