import { appStateDispatchContext } from '@/state/AppState';
import { useContext } from 'react';

// Types
import { getAllHosts, type IHostInfo } from '@/api/tango/database';
import type { IFetchType } from '@/hooks/useFetch';

export const useHostsContext = () => {
    const dispatch = useContext(appStateDispatchContext);
    const dispatchHosts = (hosts: IFetchType<IHostInfo[]>) =>
        dispatch({ type: 'CHANGE_HOST_LIST', newHosts: hosts });

    const hostsLocalStorageName = 'hosts';

    const fetchHosts = async () => {
        const instanceData: IFetchType<IHostInfo[]> = {
            value: [],
            loading: true,
            error: undefined,
        };

        try {
            instanceData.value = await getAllHosts();
        } catch (error: unknown) {
            if (error instanceof Error) instanceData.error = error;
        } finally {
            instanceData.loading = false;
        }

        dispatchHosts(instanceData);

        localStorage.setItem(
            hostsLocalStorageName,
            JSON.stringify(instanceData.value),
        );
    };

    return { dispatchHosts, fetchHosts, hostsLocalStorageName };
};
