import { useEffect, useState } from 'react';

// TODO: Crate type.ts file and move it there
export interface IFetchType<T> {
    value: T;
    loading: boolean;
    error?: Error;
}

/**
 * Hooks for asynchronous data fetching
 *
 * Remember to put every function in useCallback
 */
export function useFetch<T extends (...args: unknown[]) => unknown>(
    fn: T,
    interval: number | null = null,
): IFetchType<Awaited<ReturnType<T>>> {
    type fnType = Awaited<ReturnType<T>>;

    const [value, setValue] = useState<fnType>(undefined as fnType);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await fn();
                setValue(data as fnType);
                setError(undefined);
            } catch (err: unknown) {
                if (err instanceof Error) setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (interval) {
            fetch();

            // TODO: This will send requests periodically but without eaven
            // checking if previous request had any response.
            const intervalID = setInterval(() => fetch(), interval);
            return () => clearInterval(intervalID);
        } else fetch();
    }, [fn, interval]);

    return { value, loading, error };
}
