import { gqlRequest } from '@/api/gql/request';

import { DB_GET_ALL_DB_QUERY } from '@/api/gql/query';

import type { IDatabaseGetAllDbDTO } from '@/api/gql/types';
import type { stateType } from '../../types';

export interface IDatabaseInfo {
    name: string;
    state: stateType;
}

export async function getAllDatabases(): Promise<IDatabaseInfo[]> {
    const response = await gqlRequest<IDatabaseGetAllDbDTO>(
        DB_GET_ALL_DB_QUERY,
    );
    return response.database;
}
