import { gqlRequest } from '@/api/gql/request';

import { DB_GET_INFO_STRING_QUERY } from '@/api/gql/query';

import type { IDatabasebGetInfoStringDTO } from '@/api/gql/types';

export async function getInfoString() {
    const response = await gqlRequest<IDatabasebGetInfoStringDTO>(
        DB_GET_INFO_STRING_QUERY,
    );
    return response.info;
}
