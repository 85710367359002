//import { select } from 'radash';

// TODO: Make it work again
export const checkIfStoped = async (host: string, serverToRestart: string) => {
    return true;
};
/*
export const checkIfStoped = async (host: string, serverToRestart: string) => {
    const servers = await getServers(host);

    // Get status of server
    const status = select(
        servers,
        (s) => s.state, // what we look for
        (s) => s.name === serverToRestart, // based on what
    );

    // And check if is OFF
    if (status[0] === 'FAULT') return true;
    return false;
};
*/
