// SCSS
import './ErrorMessage.scss';

interface IErrorMessageProps {
    error: string;
}
const ErrorMessage = ({ error }: IErrorMessageProps) => {
    return <p className="errorMessage">{error}</p>;
};

export default ErrorMessage;
