import { debounce } from 'radash';
import { useContext, useState } from 'react';

// API
import type { IServerInstance } from '@/api/tango/database';
import { addServerToHost } from './libInstanceList';

// Context
import { HostContext } from '@/context/Context';

// Components
import Instance from '../Instance/Instance';
import RefreshButton from './RefreshButton/RefreshButton';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import './InstanceList.scss';

interface IInstanceListProps {
    serverInstances: IServerInstance[];
}

/**
 * Display instances list
 * @param serverInstances all server instances
 */
const InstanceList = ({ serverInstances }: IInstanceListProps) => {
    const [currentSelectedServer, setServer] = useState<string>('');
    const hostName = useContext(HostContext);

    const addServer = debounce({ delay: 300 }, () =>
        addServerToHost(hostName, currentSelectedServer),
    );

    return (
        <Col style={{ height: '100%' }}>
            <Row>
                <div className="instanceList-title">
                    <h2>Start new</h2>
                    <RefreshButton />
                </div>
            </Row>
            <Row className="instanceList-list">
                <div>
                    {
                        // IDEA: Instead of printing all servers, make a tree of servers
                        // and its instances?
                        serverInstances.map((inst) => (
                            <Instance
                                key={`${inst.server}/${inst.name}`}
                                instance={inst}
                                currentSelectedServer={currentSelectedServer}
                                setServer={setServer}
                            />
                        ))
                    }
                </div>
            </Row>
            <Row className="instanceList-start-button">
                <div>
                    <button onClick={addServer}>Start Server</button>
                </div>
            </Row>
        </Col>
    );
};

export default InstanceList;
