import useNavbarLogo from './useNavbarLogo';

import './NavbarLogo.scss';

const NavbarLogo = () => {
    const { handleLogoClick } = useNavbarLogo();
    return (
        <div>
            <button className="navbarLogo" onClick={handleLogoClick}>
                Polka
            </button>
        </div>
    );
};

export default NavbarLogo;
