import { listify } from 'radash';

// Types
import type { IHostInfo } from '@/api/tango/database';

// lib
import { getBranches } from './libBranchList';

// Components
import Branch from './Branch/Branch';

// MUI
import List from '@mui/material/List';

// Local context
import { BranchListScrollContext, useScroll } from './BranchListUtils';

interface IBranchListProps {
    hostList: IHostInfo[];
}

/**
 * List all branches
 */
const BranchList = ({ hostList }: IBranchListProps) => {
    const hostRef = useScroll();

    const allBranches = getBranches(hostList);
    return (
        <List component="div" className="deviceList-list">
            <BranchListScrollContext.Provider value={hostRef}>
                {listify(
                    allBranches,
                    (branchName: string, hosts: IHostInfo[]) => {
                        return (
                            <Branch
                                key={branchName}
                                branchName={branchName}
                                branchHostList={hosts}
                            />
                        );
                    },
                )}
            </BranchListScrollContext.Provider>
        </List>
    );
};

export default BranchList;
