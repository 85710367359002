import { useAllInstances, useInstanceContext } from '@/context/InstanceContext';
import { debounce } from 'radash';

// Types
import { type IServerInstance } from '@/api/tango/database';
import type { IFetchType } from '@/hooks/useFetch';

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// SCSS
import './RefreshButton.scss';

/**
 * Facade for refresh button.
 *
 * @return for refetching all instances.
 */
const useRefreshButton = () => {
    const currentInstances = useAllInstances();
    const { fetchInstances, dispatchInstances } = useInstanceContext();

    async function refresh() {
        const instances: IFetchType<IServerInstance[]> = {
            ...currentInstances,
            loading: true,
        };
        dispatchInstances(instances);

        fetchInstances();
    }

    return { refresh, loading: currentInstances.loading };
};

/**
 * Refresh button to refetching all servers instances
 */
const RefreshButton = () => {
    const { refresh, loading } = useRefreshButton();

    const onClick = debounce({ delay: 300 }, refresh);
    return (
        <button onClick={onClick} className="refreshButton">
            {loading ? <CircularProgress /> : <>Refresh</>}
        </button>
    );
};

export default RefreshButton;
