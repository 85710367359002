import { ChangeHostContext, ChangeTabContext } from '@/context/Context';
import { useContext } from 'react';

const useChangeHost = () => {
    const changeHost = useContext(ChangeHostContext);
    const changeTab = useContext(ChangeTabContext);

    return (newHost: string, newTab: string) => {
        changeHost(newHost);
        changeTab(newTab);
    };
};

export default useChangeHost;
