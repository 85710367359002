import type { ILog } from '@/api/mongo/logs/types';

// MUI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface ILogInstanceProps {
    log: ILog;
}

const LogInstance = ({ log }: ILogInstanceProps) => {
    return (
        <TableRow>
            <TableCell>{log.timestamp.toString()}</TableCell>
            <TableCell>{log.user}</TableCell>
            <TableCell>{log.actionType}</TableCell>
            <TableCell>{log?.name}</TableCell>
            <TableCell>{log?.argin}</TableCell>
        </TableRow>
    );
};

export default LogInstance;
