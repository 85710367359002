import { isEmpty, sleep } from 'radash';
import { useEffect } from 'react';

import { DB_GET_ALL_DB_TIMING } from '@/data/constants';
import { useDbContext } from './useDbContext';

interface IHostProviderProps {
    children: JSX.Element;
}

export const DbProvider = ({ children }: IHostProviderProps) => {
    const { dispatchDb, fetchDb, dbLocalStorageName } = useDbContext();

    // TODO: We could create some kind of subscripotion class for that
    useEffect(() => {
        const startRequestLoop = async () => {
            for (;;) await sleep(DB_GET_ALL_DB_TIMING).then(() => fetchDb());
        };

        const rawState = localStorage.getItem(dbLocalStorageName);
        const stateData = rawState ? JSON.parse(rawState) : rawState;

        // If data isn't empty set state with this data
        if (!isEmpty(stateData))
            dispatchDb({ value: stateData, loading: false });

        // And fetch fresh data anyway
        fetchDb();
        startRequestLoop();
    }, []);

    return children;
};
