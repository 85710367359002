// API
import type { IServerInfo } from '@/api/subscription';

export interface ILevelList {
    [level: string]: Array<IServerInfo>;
}

export function groupServersByLevel(serverList: IServerInfo[]) {
    const levelList: ILevelList = {};
    for (const server of serverList) {
        // if level already exist then push server there
        if (levelList[server.level]) levelList[server.level].push(server);
        else levelList[server.level] = [server];
    }
    return levelList;
}
