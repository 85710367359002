import { debounce } from 'radash';
import { useState } from 'react';

import { startNew } from './libCreateServer';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// MUI
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';

// SCSS
import './CreateServer.scss';

/**
 * Form for creating new Tango server
 */
const CreateServer = () => {
    const [server, setServer] = useState<string>('');
    const [tangoClass, setTangoClass] = useState<string>('');
    const [devices, setDevices] = useState<string>('');

    // Functions
    const handleServer = (event: React.ChangeEvent<HTMLInputElement>) =>
        setServer(event.target.value);
    const handleTangoClass = (event: React.ChangeEvent<HTMLInputElement>) =>
        setTangoClass(event.target.value);
    const handleDevices = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        setDevices(event.target.value);

    const onClick = debounce({ delay: 300 }, () =>
        startNew(server, tangoClass, devices),
    );
    return (
        <Col>
            <Row>
                <h2>Create server</h2>
            </Row>
            <Row>
                <div>
                    <div className="createServer-form-field">
                        <FormLabel>Server (ServerName/Instance)</FormLabel>
                        <Input onChange={handleServer} />
                    </div>
                    <div className="createServer-form-field">
                        <FormLabel>Class</FormLabel>
                        <Input value={tangoClass} onChange={handleTangoClass} />
                    </div>
                    <div className="createServer-form-field">
                        <FormLabel>Devices</FormLabel>
                        <Textarea
                            onChange={handleDevices}
                            minRows={4}
                            maxRows={4}
                            placeholder="domain/family/member"
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="createServer-form-button"
                        onClick={onClick}
                    >
                        Register Server
                    </button>
                </div>
            </Row>
        </Col>
    );
};

export default CreateServer;
