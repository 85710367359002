export type DevType = string | null;

export class DbDevExportInfo {
    name: DevType;
    ior: DevType;
    host: DevType;
    pid: DevType;
    ver: DevType;

    constructor(
        name: DevType,
        ior: DevType,
        host: DevType,
        pid: DevType,
        ver: DevType,
    ) {
        this.name = name;
        this.ior = ior;
        this.host = host;
        this.pid = pid;
        this.ver = ver;
    }
}
