import { useContext } from 'react';

// Types
import type { IDatabaseInfo } from '@/api/tango/database';

// Context
import { ChangeHostContext, ChangeTabContext } from '@/context/Context';

// Hooks
import { drawerContext } from '@/app/Cloud/CloudDeviceList/DeviceListDrawer/DeviceListDrawer';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';

// MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

interface IDatabaseListProps {
    db: IDatabaseInfo[];
}

/**
 * List all databases
 *
 * NOTE: We can have more than one database but usualy
 * we use only one, that's why we display only one.
 */
const DatabaseList = ({ db }: IDatabaseListProps) => {
    const changeMobileDrawer = useContext(drawerContext);
    const changeGlobalHost = useContext(ChangeHostContext);
    const changeTab = useContext(ChangeTabContext);

    const databaseName = 'Database';
    const databaseState = db[0].state;

    const handleClick = () => {
        changeGlobalHost('Database');
        changeTab('Info');
        changeMobileDrawer(false);
    };

    return (
        <List className="deviceList-list">
            <ListItem disablePadding>
                <ListItemButton
                    className="deviceList-item"
                    disableGutters
                    onClick={handleClick}
                >
                    <ListItemText className="deviceList-item-header">
                        <StatusDot state={databaseState} size="medium" />
                        <span className="deviceList-item-header-name">
                            {databaseName}
                        </span>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </List>
    );
};

export default DatabaseList;
