import { useContext } from 'react';

// Context
import { SearchContext } from '@/context/Context';

// API
import type { IServerInfo } from '@/api/subscription';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';
import ServerInstanceMenu from './ServerInstanceMenu/ServerInstanceMenu';

// MUI
import ListItem from '@mui/material/ListItem/ListItem';

// SCSS
import './ServerInstance.scss';

interface IServerInstanceProps {
    serverInfo: IServerInfo;
}

/**
 * Print server name, status and menu with options
 *
 * @param serverInfo inforamtion about particular server
 */
const ServerInstance = ({ serverInfo }: IServerInstanceProps) => {
    // Server data
    const serverName = serverInfo.name;
    const serverState = serverInfo.state;

    // Server style
    const { server: searchedServer } = useContext(SearchContext);
    const searchedStyle =
        serverName === searchedServer ? 'serverInstance-searched' : '';

    return (
        <ListItem
            aria-selected={serverName === searchedServer}
            aria-label={serverName}
            role="listitem"
            className={`serverInstance ${searchedStyle}`}
        >
            <StatusDot state={serverState} size="small" />
            <p>{serverName}</p>
            <ServerInstanceMenu serverInfo={serverInfo} />
        </ListItem>
    );
};

export default ServerInstance;
