import { isEmpty, sleep } from 'radash';
import { useEffect } from 'react';

import { DB_GET_HOSTS_TIMING } from '@/data/constants';
import { useHostsContext } from './useHostsContext';

interface IHostProviderProps {
    children: JSX.Element;
}

const HostsProvider = ({ children }: IHostProviderProps) => {
    const { dispatchHosts, fetchHosts, hostsLocalStorageName } =
        useHostsContext();

    useEffect(() => {
        const startRequestLoop = async () => {
            for (;;) {
                await sleep(DB_GET_HOSTS_TIMING);
                fetchHosts();
            }
        };

        const rawState = localStorage.getItem(hostsLocalStorageName);
        const stateData = rawState ? JSON.parse(rawState) : rawState;

        // If data isn't empty set state with this data
        if (!isEmpty(stateData))
            dispatchHosts({ value: stateData, loading: false });

        // And fetch fresh data anyway
        fetchHosts();
        startRequestLoop();
    }, []);

    return children;
};

export default HostsProvider;
