import type { globalStateAllActions, IGlobalState } from './types';

export function appStateReducer(
    state: IGlobalState,
    action: globalStateAllActions,
) {
    switch (action.type) {
        case 'CHANGE_DB_LIST':
            return { ...state, db: action.newDb };

        case 'CHANGE_HOST_LIST':
            return { ...state, hosts: action.newHosts };

        case 'CHANGE_SERVER_LIST': {
            const mutState = { ...state };

            const hostName = Object.keys(action.newServers).pop() ?? '';
            const serverList = action.newServers[hostName];

            mutState.hostServers[hostName] = serverList;
            return mutState;
        }

        case 'CHANGE_INSTANCE_LIST':
            return { ...state, inst: action.newInstances };
    }
}
