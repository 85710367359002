import { useDeviceData } from '@/context/DeviceData';
import { isEmpty } from 'radash';

// Components
import CloudContent from '@/app/Cloud/CloudContent/CloudContent';
import CloudNavbar from '@/app/Cloud/CloudNavbar/CloudNavbar';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import Loading from '@/components/Loading/Loading';

// SCSS
import './CloudBody.scss';

const CloudBody = () => {
    const { databaseList, hostList, error } = useDeviceData();

    // If no data is provided, display loading or error (if occurred) instead of content
    if (isEmpty(databaseList) || isEmpty(hostList)) {
        if (error) {
            return (
                <div className="cloudBody-error-message">
                    <ErrorMessage error={error.message} />
                </div>
            );
        } else {
            return <Loading />;
        }
    } else {
        return (
            <>
                <CloudNavbar />
                <CloudContent databaseList={databaseList} hostList={hostList} />
            </>
        );
    }
};

export default CloudBody;
