import { getCommandRespond, gqlRequestCommand } from '@/api/gql/request';

import { DATABASEDS_DEV } from '@/data/constants';

// Unexport device
export async function unexportDevice(dev: string) {
    const response = await gqlRequestCommand(
        DATABASEDS_DEV,
        'DbUnExportDevice',
        dev,
    );
    const unExportResp = getCommandRespond(response);

    if (unExportResp.ok) return 'Success';
    else throw new Error(`Error: ${unExportResp.message.join()}`);
}
