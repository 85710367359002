import type { IFetchLogsResponseDTO } from '@/api/mongo/logs/types';
import LogFooter from '../LogFooter/LogFooter';
import LogList from '../LogList/LogList';

interface ILogViewerProps {
    response: IFetchLogsResponseDTO;
    page: number;
    setPage: (page: number) => void;
}

const LogViewer = ({ response, page, setPage }: ILogViewerProps) => {
    const changePage = (event: React.ChangeEvent<unknown>, value: number) =>
        setPage(value);

    const totalLogs = response.device?.totalRecordCount;
    const pagesAmount = totalLogs ? Math.ceil(totalLogs / 10) : 0;
    const logs = response.device.userActions;

    return (
        <>
            <LogList logs={logs} />
            <LogFooter
                currentPage={page}
                pagesAmount={pagesAmount}
                changePage={changePage}
            />
        </>
    );
};

export default LogViewer;
