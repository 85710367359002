import Pagination from '@mui/material/Pagination';

import './LogFooter.scss';

interface ILogFooterProps {
    currentPage: number;
    pagesAmount: number;
    changePage: (event: React.ChangeEvent<unknown>, value: number) => void;
}

/**
 * Display pagination bar
 */
const LogFooter = ({
    currentPage,
    pagesAmount,
    changePage,
}: ILogFooterProps) => {
    if (pagesAmount > 0) {
        return (
            <div className="logFooter">
                <Pagination
                    page={currentPage}
                    count={pagesAmount}
                    onChange={changePage}
                />
            </div>
        );
    } else return null;
};

export default LogFooter;
