import packageInfo from '../../package.json';

// Polka
export const POLKA_VERSION = packageInfo.version;

// Tango
export const DATABASEDS_DEV = 'sys/database/2';

// Taranta auth
export const JWT_COOKIE_NAME = 'taranta_jwt';

// Timings
export const DEFAULT_TIMING = 3000;
export const DB_GET_ALL_DB_TIMING = 10000;
export const DB_GET_HOSTS_TIMING = 10000;
export const DB_GET_ALL_INSTANCES_TIMING = 10000;

// Logs
export const LOG_LOG_AMOUNT_ON_PAGE = 8;
