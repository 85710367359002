// API
import type { IServerInfo } from '@/api/subscription';

export interface ILevelPreview {
    running: number;
    turnedOff: number;
    other: number;
}

// Get amount of running and turned off servers
export function countLevelPreview(servers: IServerInfo[]): ILevelPreview {
    let running = 0;
    let turnedOff = 0;
    let other = 0;

    for (const server of servers) {
        if (server.state == 'ON') running++;
        else if (
            server.state === 'OFF' ||
            server.state === 'UNKNOWN' ||
            server.state === 'FAULT'
        )
            turnedOff++;
        else other++;
    }
    return { running, turnedOff, other };
}

export function getLevelState(servers: IServerInfo[]) {
    for (const server of servers) if (server.state !== 'ON') return 'UNKNOWN';
    return 'ON';
}

// Check if searcehd host is in this level
export function checkIfserched(serverList: IServerInfo[], searched: string) {
    for (const inst of serverList) {
        if (inst.name === searched) return true;
    }
    return false;
}
