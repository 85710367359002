import useWindow from '@/hooks/useWindow';

export interface IBreakpointProps {
    xs?: boolean;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    xl?: boolean;
    xxl?: boolean;

    children: JSX.Element | JSX.Element[];
}

/**
 * Component used for displaying content depend on current
 * window size.
 *
 * e.g. " <Breakpoint lg>...</Breakpoint> " this component should display
 * it's childs after screen hit lg breakpoint
 *
 * " <Breakpoint xs lg={false}>...</Breakpoint> " but this should display
 * content only to lg breakpoint
 *
 */
const Breakpoint = (props: IBreakpointProps) => {
    const win = useWindow();

    type brType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    const br: brType[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

    // useMemo?
    let doDisplay = null;
    for (const point of br) {
        // We need to explicitly check for undefined as we want to
        // check if props exist.
        if (props[point] === undefined) continue;

        // Check the value of breakpoint
        if (win[point]) doDisplay = props[point];
    }

    // We want to return JSX.Element thats why react fragment here
    return doDisplay ? <>{props.children}</> : null;
};

export default Breakpoint;
