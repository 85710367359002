import { debounce } from 'radash';
import { useState } from 'react';

// Hooks
import useSearchBar from './useSearchBar/useSearchBar';
// Shared
import SearchTagList from '../SearchTagList/SearchTagList';

// MUI
import Input from '@mui/joy/Input';

// SCSS
import { notfiyError, notifySuccess } from '@/utils/notification';
import './SearchBar.scss';
import SearchBarButton from './SearchBarButton/SearchBarButton';

/**
 * Search bar for server or host
 */
const SearchBar = () => {
    const [searchedString, setSearched] = useState('');
    const search = useSearchBar();

    // Form functions
    const changeSearchedString = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSearched(event.target.value);

    const makeSearch = debounce(
        { delay: 300 },
        async (event: React.KeyboardEvent | React.MouseEvent) => {
            try {
                const keyboard = event as React.KeyboardEvent;
                if (keyboard.key === 'Enter') {
                    const message = search(searchedString);
                    notifySuccess(message);
                }
            } catch (error) {
                if (error instanceof Error) notfiyError(error.message);
            }
        },
    );

    return (
        <div className="searchBar">
            <Input
                onChange={changeSearchedString}
                onKeyDown={makeSearch}
                size="sm"
                placeholder="Search server"
                endDecorator={<SearchBarButton />}
                sx={{
                    '--Input-decoratorChildHeight': '2rem',
                    'width': '100%',
                }}
            />
            <SearchTagList />
        </div>
    );
};

export default SearchBar;
