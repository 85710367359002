import { getCommandRespond, gqlRequestCommand } from '@/api/gql/request';

import { DATABASEDS_DEV } from '@/data/constants';

// Put inforamtion about server to database
export async function putServerInfo(
    server: string,
    host: string,
    controlled: string,
    level: string,
) {
    const response = await gqlRequestCommand(
        DATABASEDS_DEV,
        'DbPutServerInfo',
        [server, host, controlled, level],
    );

    const putResp = getCommandRespond(response);
    if (putResp.ok) return 'Success';
    else throw new Error(`Error: ${putResp.message.join()}`);
}
