import { appStateDispatchContext } from '@/state/AppState';
import { useContext } from 'react';

import { getAllInstances, type IServerInstance } from '@/api/tango/database';
import type { IFetchType } from '@/hooks/useFetch';

/**
 * Facade for instance list state
 */
export const useInstanceContext = () => {
    const dispatch = useContext(appStateDispatchContext);
    const dispatchInstances = (instances: IFetchType<IServerInstance[]>) =>
        dispatch({ type: 'CHANGE_INSTANCE_LIST', newInstances: instances });

    const instanceLocalStorageName = 'inst';

    const fetchInstances = async () => {
        const instanceData: IFetchType<IServerInstance[]> = {
            value: [],
            loading: true,
            error: undefined,
        };

        try {
            instanceData.value = await getAllInstances();
        } catch (error: unknown) {
            if (error instanceof Error) instanceData.error = error;
        } finally {
            instanceData.loading = false;
        }

        dispatchInstances(instanceData);

        localStorage.setItem(
            instanceLocalStorageName,
            JSON.stringify(instanceData.value),
        );
    };

    return { fetchInstances, dispatchInstances, instanceLocalStorageName };
};
