import { useEffect, useState } from 'react';
// Shared
import SearchTag from '../SearchTag/SearchTag';
// SCSS
import './SearchTagList.scss';

/**
 * Component that displays a list of predefined search tags that represent host or server
 */

interface ISearchTagListProps {
    initialTags?: string[];
}

const SearchTagList = ({ initialTags = [] }: ISearchTagListProps) => {
    const [tags, setTags] = useState<string[]>(initialTags);

    useEffect(() => {
        if (window.POLKA_CONFIG && window.POLKA_CONFIG.searchTags) {
            setTags(window.POLKA_CONFIG.searchTags);
        }
    }, [window.POLKA_CONFIG]);

    return (
        <div className="searchTagList-tabs">
            {tags.map((name) => (
                <SearchTag key={name} name={name} />
            ))}
        </div>
    );
};

export default SearchTagList;
