import { AppStateProvider } from '@/state/AppState';
import { DbProvider } from './DbContext';
import HostsProvider from './HostsContext/HostsProvider';
import { InstanceProvider } from './InstanceContext';
import { ServerListProvider } from './ServerListContext/ServerListContext';

interface IStateProviderProps {
    children: JSX.Element;
}

/**
 * Provider for all state context handlers
 */
const StateProvider = ({ children }: IStateProviderProps) => {
    return (
        <AppStateProvider>
            <InstanceProvider>
                <HostsProvider>
                    <DbProvider>
                        <ServerListProvider>{children}</ServerListProvider>
                    </DbProvider>
                </HostsProvider>
            </InstanceProvider>
        </AppStateProvider>
    );
};

export default StateProvider;
