import { REQ_EXECUTE_COMMAND_QUERY } from '@/api/gql/query';
import { request } from 'graphql-request';

// Types
import type { IReqExecuteCommandDTO } from '@/api/gql/types';
import type { Variables } from 'graphql-request';
import saveLogs from '../mongo/logs/saveLogs';

const API_ENDPOINT = '/db';

// graphql-request errors are long and contain a lot unnecessary
// inforamtion that's why we want to parse it
export function gqlErrorParse(resp: unknown) {
    const raw = JSON.stringify(resp, undefined, 2);
    const error = JSON.parse(raw);

    const errorMsg = error.response.error;

    return errorMsg;
}

/**
 * Wraper function for graphql-request library
 *
 * @param query
 * @param variables
 */
export async function gqlRequest<RequestType>(
    query: string,
    variables: Variables = {},
) {
    return await request<RequestType>({
        url: API_ENDPOINT,
        document: query,
        variables: variables,
    }).catch((err) => {
        const gqlError = gqlErrorParse(err);
        throw new Error(gqlError);
    });
}

type gqlCommandVariable = string | number | Array<unknown>;

export async function gqlRequestCommand(
    device: string,
    command: string,
    argin: gqlCommandVariable,
) {
    /*
     * TangoGQL request for exectutin command on device
     *
     * device - device e.g. tango/admin/tangobox
     * command - e.g. DevStart
     * argin - arguments
     */
    const commandResponse = await gqlRequest<IReqExecuteCommandDTO>(
        REQ_EXECUTE_COMMAND_QUERY,
        {
            device,
            command,
            argin,
        },
    );
    if (commandResponse.executeCommand.ok) {
        const timestamp = new Date();
        await saveLogs({
            actionType: 'ExcuteCommandUserAction',
            timestamp,
            tangoDB: window.POLKA_CONFIG.beamline,
            device,
            name: command,
            argin: String(argin),
        });
    }

    return commandResponse;
}

// TODO: Implement
//export async function gqlRequestAttribute() {}

export function getCommandRespond(resp: IReqExecuteCommandDTO) {
    return resp.executeCommand;
}
