import type { ILog } from '@/api/mongo/logs/types';

// Components
import LogInstance from '../LogInstance/LogInstance';

// MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'radash';

import './LogList.scss';

interface ILogListProps {
    logs: ILog[];
}

const LogList = ({ logs }: ILogListProps) => {
    if (isEmpty(logs)) return <p>It looks like there's no logs</p>;
    else
        return (
            <div className="logList">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Action type</TableCell>
                                <TableCell>Command</TableCell>
                                <TableCell>Arguments</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map((log) => (
                                <LogInstance log={log} key={log._id} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
};

export default LogList;
