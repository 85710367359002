import { useContext, useState } from 'react';

// API
import { setNewLevel } from './libSetStartulLevel';

// Types
import type { IServerInfo } from '@/api/subscription';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';
import type { SelectChangeEvent } from '@mui/material/Select';

//Context
import { HostContext } from '@/context/Context';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { createServerInstanceMenuItem } from '../instanceDialogs';

interface ISetStartupLevelProps extends IDialogComponentProps {
    server: IServerInfo;
}

/**
 * In this dialog we change startup level of Tango server
 */
const SetStartupLevel = ({ server, isOpen, close }: ISetStartupLevelProps) => {
    const [controlled, setControlled] = useState(server.controlled);
    const [level, setLevel] = useState(server.level);
    const hostName = useContext(HostContext);

    // Functions
    const controlledChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setControlled(event.target.value);

    const changeLevel = (event: SelectChangeEvent<typeof level>) =>
        setLevel(event.target.value);

    const confirmChange = () => {
        setNewLevel(hostName, server.name, controlled, level);
        close();
    };

    // If it's not controlled then disable level select
    const isServerControlledBy = controlled === '0' ? true : false;
    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{`${server.name} running on ${hostName}`}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pb: 0,
                }}
            >
                <FormControl>
                    <FormLabel>Controlled by Starter:</FormLabel>
                    <RadioGroup
                        row
                        value={controlled}
                        onChange={controlledChange}
                    >
                        <FormControlLabel
                            value={'1'}
                            control={<Radio />}
                            label="Yes"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={'0'}
                            control={<Radio />}
                            label="No"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl
                    sx={{
                        maxWidth: 120,
                    }}
                    disabled={isServerControlledBy}
                >
                    <FormLabel>Startup level:</FormLabel>
                    <Select value={level} onChange={changeLevel}>
                        <MenuItem value={'0'}>None</MenuItem>
                        <MenuItem value={'1'}>Level 1</MenuItem>
                        <MenuItem value={'2'}>Level 2</MenuItem>
                        <MenuItem value={'3'}>Level 3</MenuItem>
                        <MenuItem value={'4'}>Level 4</MenuItem>
                        <MenuItem value={'5'}>Level 5</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={confirmChange}>OK</Button>
                <Button onClick={close}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const createSetStartupLevel = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, SetStartupLevel, null);

export default createSetStartupLevel;
