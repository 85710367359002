import type { IMongoSchema } from '../schema';

/**
 * Save user logs to log microservice
 */
export default async function saveLogs(data: IMongoSchema) {
    try {
        const response = await fetch('/logs/saveUserAction', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(data),
        });
        if (response.status !== 200)
            throw new Error('Failed so save user action');
    } catch (error: unknown) {
        if (error instanceof Error)
            console.log(`saveUserAction: ${error.message}`);
    }
}
