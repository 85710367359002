import { useCallback, useContext } from 'react';

import getStarterLogs from '@/api/tango/host/getStarterLogs/getStarterLogs';

import { HostContext } from '@/context/Context';
import { useFetch } from '@/hooks/useFetch';

import Loading from '@/components/Loading/Loading';
import StarterLogViewer from './StarterLogViewer/StarterLogViewer';

const HostStarterLogs = () => {
    const hostName = useContext(HostContext);

    const getLogs = useCallback(() => getStarterLogs(hostName), [hostName]);
    const { value: logs, loading, error } = useFetch(getLogs);

    if (error) return <p>{error.message}</p>;
    else if (loading) return <Loading />;
    else return <StarterLogViewer starterLogs={logs} />;
};

export default HostStarterLogs;
