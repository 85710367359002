import { useCallback, useContext, useState } from 'react';

// API
import fetchLogs from '@/api/mongo/logs/fetchLogs';
import { useFetch } from '@/hooks/useFetch';

// Context
import { HostContext } from '@/context/Context';

const useHostLogs = () => {
    const [page, setPage] = useState(1);

    // TODO: Make custom hook for HostContext
    const hostName = useContext(HostContext);
    const fullHostName = `tango/admin/${hostName}`;

    // Fetch logs
    const fetch = useCallback(
        () => fetchLogs(fullHostName, page),
        [hostName, page],
    );
    const { value, loading, error } = useFetch(fetch);

    return { value, loading, error, page, setPage };
};

export default useHostLogs;
