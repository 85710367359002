import { listify } from 'radash';
import React, { useContext } from 'react';

// Context
import { ChangeTabContext, HostContext, TabContext } from '@/context/Context';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import { useSession } from '@/context/Session';
import './TabNavbar.scss';

export interface ITabEntry {
    component: React.ElementType;
    requireAuth?: boolean;
}

export interface ITabTemplate {
    [tabName: string]: ITabEntry;
}

export const TabNavbar = ({ tabList }: { tabList: ITabTemplate }) => {
    const currentTab = useContext(TabContext);
    const changeTab = useContext(ChangeTabContext);
    const hostName = useContext(HostContext);
    const session = useSession();

    return (
        <>
            <Row>
                <Col>
                    <h2>{hostName}</h2>
                </Col>
            </Row>
            <Row className="tabNavbar-tabs">
                <Col>
                    {listify(tabList, (tabName: string, value: ITabEntry) => {
                        if (value.requireAuth && !session.isLoggedIn) return;
                        const isCurrentTab =
                            tabName === currentTab ? 'tabNavbar-current' : '';
                        const onClick = () => changeTab(tabName);

                        return (
                            <button
                                key={tabName}
                                className={`tabNavbar-button ${isCurrentTab}`}
                                onClick={onClick}
                            >
                                {tabName}
                            </button>
                        );
                    })}
                </Col>
            </Row>
        </>
    );
};
