import type { IStarterLogs } from '@/api/tango/host';

// MUI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface IStarterLogInstanceProps {
    log: IStarterLogs;
}
const StarterLogInstance = ({ log }: IStarterLogInstanceProps) => {
    return (
        <TableRow>
            <TableCell>{log.date}</TableCell>
            <TableCell>{log.time}</TableCell>
            <TableCell>{log.message}</TableCell>
        </TableRow>
    );
};

export default StarterLogInstance;
