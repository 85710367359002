import { isEmpty } from 'radash';

export interface IStarterLogs {
    date: string;
    time: string;
    message: string;
}

/**
 * Split date string from request and return object with date and time
 * The string have format:
 *      31/08/23   10:04:41     between them there are 3 spaces
 *
 * @param timeString - string with date and time
 * @returns object with date and time
 */
export function divideTimeString(timeString: string) {
    // Split srting with regex
    const [date, time] = timeString.split(/\s+/);
    return { date, time };
}

/**
 * Convert response to array of logs
 *
 * @param responseOutput - response from TangoGQL
 * @returns array of starter logs
 */
export function parseStarterLogsresponse(
    responseOutput: string,
): IStarterLogs[] {
    const rawLogsArray = responseOutput.split('\n');

    const logArray: IStarterLogs[] = [];
    for (const logString of rawLogsArray) {
        if (isEmpty(logString)) continue;

        const [timeString, message] = logString.split('\t');

        const { date, time } = divideTimeString(timeString);

        logArray.push({ date, time, message });
    }
    return logArray;
}
