// Constants
import { POLKA_VERSION } from '@/data/constants';

// Components
import Auth from '@/app/Auth/Auth';
import NavbarLogo from './NavbarLogo/NavbarLogo';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import './CloudNavbar.scss';

/**
 * Display logo, version and authentication status
 */
const CloudNavbar = () => {
    return (
        <Row style={{ height: '8%' }}>
            <Col xs={12} style={{ height: '100%' }}>
                <div className="navbar">
                    <NavbarLogo />
                    <div className="navbar-info">
                        <Auth />
                        <p className="navbar-version">{POLKA_VERSION}</p>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CloudNavbar;
