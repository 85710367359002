import { useEffect, useState } from 'react';

interface IUseWindow {
    size: number;
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    xxl: boolean;
}

/**
 * Check size of the window and determine which bootstrap
 * breakpoint have been hit
 */
const useWindow = (): IUseWindow => {
    const [size, setSize] = useState(window.innerWidth);

    useEffect(() => {
        function handleWindowResize() {
            setSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // Bootstrap breakpoints
    const xs = 0;
    const sm = 576;
    const md = 768;
    const lg = 992;
    const xl = 1200;
    const xxl = 1400;

    const hit = (x: number, min: number) => x > min;
    return {
        size: size,
        xs: hit(size, xs),
        sm: hit(size, sm),
        md: hit(size, md),
        lg: hit(size, lg),
        xl: hit(size, xl),
        xxl: hit(size, xxl),
    };
};

export default useWindow;
