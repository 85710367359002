import { Col } from 'react-bootstrap';
import Level from '../Level/Level';

// Types
import type { ILevelList } from '../LevelPanel/libLevelPanel';

interface ILevelColumnProps {
    levelIndexes: number[];
    levelMap: ILevelList;
    levelListsState: boolean[];
    setListOpen: (which: number, state: boolean) => void;
}

export const LevelColumn = ({
    levelIndexes,
    levelMap,
    levelListsState,
    setListOpen,
}: ILevelColumnProps) => {
    const HEIGHT_DIVIDENT = 100;
    const DONT_DIVIDE_HEIGHT = 0;

    return (
        <Col xs={12} lg={6} xxl={4} style={{ height: '100%' }}>
            {levelIndexes.map((lvl) => {
                const serversInLevel = levelMap[String(lvl)];
                const isOpen = levelListsState[lvl];

                // Count height
                const heightFactor = levelIndexes.length;
                const levelHeight =
                    heightFactor > 3
                        ? DONT_DIVIDE_HEIGHT
                        : HEIGHT_DIVIDENT / heightFactor;

                return (
                    <Level
                        key={lvl}
                        level={lvl}
                        serversInLevel={serversInLevel}
                        height={levelHeight}
                        isOpen={isOpen}
                        setListOpen={setListOpen}
                    />
                );
            })}
        </Col>
    );
};
