import { debounce } from 'radash';
import { useContext, useState } from 'react';

// API
import { login } from '@/api/auth/login';
import { validateForm } from './libLoginDialog';

import { ChangeSessionContext } from '@/context/Session';

// Notification
import { notifySuccess } from '@/utils/notification';

// Compontnts
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';

// MUI
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// SCSS
import './LoginDialog.scss';

interface ILoginProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

// TODO: Maybe loading indicator

/**
 * Dialog with login form
 */
const LoginDialog = ({ open, setOpen }: ILoginProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const changeCurrentSession = useContext(ChangeSessionContext);

    // Functions
    const handlePromptClose = () => setOpen(false);
    const handleSubmit = debounce({ delay: 300 }, async () => {
        try {
            validateForm(username, password);
            const userInfo = await login(username, password);
            changeCurrentSession({ isLoggedIn: true, data: userInfo });

            notifySuccess('Successfully logged in');
            handlePromptClose();
        } catch (error: unknown) {
            if (error instanceof Error) setError(error.message);
        }
    });
    return (
        <Dialog open={open} onClose={handlePromptClose}>
            <DialogContent>
                <FormControl>
                    <FormLabel className="loginDialog-header">Log in</FormLabel>
                    <Input
                        className="loginDialog-input"
                        type="text"
                        onChange={(event) => setUsername(event.target.value)}
                        placeholder="Username"
                    />

                    <Input
                        className="loginDialog-input"
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Password"
                    />
                    <Button onClick={handleSubmit}>Log in</Button>
                    <ErrorMessage error={error} />
                </FormControl>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
