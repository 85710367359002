import { useState } from 'react';

export const useLevelList = (levelAmount: number) => {
    const [listsState, setLevelOpen] = useState<boolean[]>(
        Array(levelAmount).fill(true),
    );

    const openAll = () => {
        const mut = [...listsState];
        setLevelOpen(mut.fill(true));
    };
    const closeAll = () => {
        const mut = [...listsState];
        setLevelOpen(mut.fill(false));
    };
    const setListState = (which: number, state: boolean) => {
        const mut = [...listsState];
        mut[which] = state;
        setLevelOpen(mut);
    };

    return { listsState, openAll, closeAll, setListState };
};
