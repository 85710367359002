// API
import type * as Database from '@/api/tango/database';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';

// SCSS
import './Instance.scss';

interface IInstanceProps {
    instance: Database.IServerInstance;
    currentSelectedServer: string;
    setServer: (newServer: string) => void;
}

/**
 * Display server instance
 */
const Instance = ({
    instance,
    currentSelectedServer,
    setServer,
}: IInstanceProps) => {
    const instanceFullName = `${instance.server}/${instance.name}`;
    const instanceState = instance.state;

    const isSelected =
        currentSelectedServer === instanceFullName ? 'instance-selected' : '';

    // Toogle current selected server
    const onClick = () => {
        if (instanceFullName === currentSelectedServer) setServer('');
        else setServer(instanceFullName);
    };

    return (
        <button onClick={onClick} className={`instance ${isSelected}`}>
            <StatusDot state={instanceState} size="small" />
            <p>{instanceFullName}</p>
        </button>
    );
};

export default Instance;
