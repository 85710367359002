import { isEmpty } from 'radash';

import type { IServersSubscriptionDTO } from '@/api/gql/types';
import type { stateType } from '@/api/tango/types';
import type { IServerInfo } from './serverSubscription';

/**
 * Get array with all servers controled by host
 *
 * TangoGQL return an array of strings where each looks like this:
 * [server name]\t[states]\t[controlled]\t[level]\t[nb instances]
 *
 * the string is without brackets of course.
 *
 * The purpose of this function is to create readable object for each server.
 */
export function parseServersResponse(
    dto: IServersSubscriptionDTO,
): IServerInfo[] {
    const serverList = dto.servers;
    if (isEmpty(serverList)) return [];

    // Value can be null
    const serverListValue = serverList.value;
    if (serverListValue === null) return [];

    return serverListValue.map((infoString: string) => {
        const values = infoString.split('\t');

        return {
            name: values[0],
            state: values[1] as unknown as stateType,
            controlled: values[2],
            level: values[3],
            instance: values[4],
        };
    });
}
