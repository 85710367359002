// MUI
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/joy/Button';

const SearchBarButton = () => {
    return (
        <Button
            className="searchBar-button"
            variant="solid"
            type="submit"
            size="sm"
            sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }}
        >
            <SearchIcon />
        </Button>
    );
};

export default SearchBarButton;
