// API
import { countLevelPreview, getLevelState } from './libLevel';
import useLevelSearch from './useLevelSearch';

// Types
import type { IServerInfo } from '@/api/subscription';

// Components
import LevelHeader from '../LevelHeader/LevelHeader';
import LevelServerList from '../LevelServerList/LevelServerList';

// Bootstrap
import { Row } from 'react-bootstrap';

// SCSS
import './Level.scss';

interface ILevelProps {
    level: number;
    serversInLevel: IServerInfo[];
    height: number;
    isOpen: boolean;
    setListOpen: (which: number, state: boolean) => void;
}

/**
 * Display information about level and list of all servers
 *
 * TODO: We acan apply compond component here
 *
 * @param level
 * @param serversInLevel
 */
const Level = ({
    level,
    serversInLevel,
    height,
    isOpen,
    setListOpen,
}: ILevelProps) => {
    const isSearched = useLevelSearch(level, serversInLevel, setListOpen);

    const levelState = getLevelState(serversInLevel);
    const toggle = () => setListOpen(level, !isOpen);
    const levelPreview = countLevelPreview(serversInLevel);

    return (
        <Row style={{ height: `${height || 'none'}%` }}>
            <div className="level">
                <LevelHeader
                    level={level}
                    levelState={levelState}
                    isSearched={isSearched}
                    toggleList={toggle}
                    levelPreview={levelPreview}
                />
                <LevelServerList
                    serversInLevel={serversInLevel}
                    isListOpen={isOpen}
                />
            </div>
        </Row>
    );
};

export default Level;
