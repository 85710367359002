import { isEmpty } from 'radash';

import { CLIENT_PING_QUERY } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { IClientPingDTO } from '@/api/gql/types';

/**
 * TODO: Implement errors
 {
  "errors": [
    {
      "reason": "API_CommandFailed",
      "desc": "Failed to execute command_inout on device sys/database/2, command DbGetDeviceInfo"
    }
  ],
  "data": {
    "device": null
  }
 }
*/

/**
 * Ping server
 *
 * As TangoGQL doesn't have ping capability, we just check
 * status of dserver.
 */
export async function ping(server: string) {
    if (isEmpty(server)) throw new Error('Server name is empty');

    const dserver = `dserver/${server}`;

    const response = await gqlRequest<IClientPingDTO>(CLIENT_PING_QUERY, {
        dev: dserver,
    });

    const attributeTab = response?.device?.attributes;
    if (attributeTab.length) {
        const value = attributeTab[0].value;
        return value === 'ON';
    }
    return false;
}
