import { isEmpty } from 'radash';
import { useContext } from 'react';

// Context
import { HostContext } from '@/context/Context';

// Components
import LevelPanel from './LevelPanel/LevelPanel';

// Bootstrap
import { useServerList } from '@/context/ServerListContext';
import { Col } from 'react-bootstrap';

/**
 * Component for fetching and displaying servers controlled
 * by host. Servers are divided by levels
 */
const HostServers = () => {
    const hostName = useContext(HostContext);
    const hostServerList = useServerList();

    const serverList = hostServerList[hostName];

    return (
        <Col>
            {isEmpty(serverList) ? (
                <p>There are no servers here</p>
            ) : (
                <LevelPanel serverList={serverList} />
            )}
        </Col>
    );
};

export default HostServers;
