import { createContext, useReducer } from 'react';
import { appStateReducer } from './stateReducer';

// Types
import type { dispatchFunctionType, IGlobalState } from './types';

const initAppState = {
    db: { value: [], loading: false, error: undefined },
    hosts: { value: [], loading: false, error: undefined },
    hostServers: {},
    inst: { value: [], loading: false, error: undefined },
};

export const appStateContext = createContext<IGlobalState>(initAppState);
export const appStateDispatchContext = createContext<dispatchFunctionType>(
    () => undefined,
);

// Provider
interface IAppStateProviderProps {
    children: JSX.Element;
}

/**
 * Provide global state through react context API.
 */
export const AppStateProvider = ({ children }: IAppStateProviderProps) => {
    const [globalState, globalStateDispatch] = useReducer(
        appStateReducer,
        initAppState,
    );

    return (
        <appStateContext.Provider value={globalState}>
            <appStateDispatchContext.Provider value={globalStateDispatch}>
                {children}
            </appStateDispatchContext.Provider>
        </appStateContext.Provider>
    );
};
